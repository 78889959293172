.container {
    width: 100%;
    background: #f5f5f5;
    height: 100vh;
    padding: 2% 0;
}

.wrapper {
    padding: 2% 20px;
    width: 88%;
    margin: auto;
    background: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wrapper h5 {
    font-weight: 500;
    font-size: 18px;
}

.form {
    padding: 40px 0;
    width: 80%;
    margin: auto;
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 1fr 1fr;
}

.heading {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    margin-top: 15px;
    line-height: 24px;
    text-align: center;
}

.sub_heading {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    border-bottom: 1px solid #000;
    text-align: center;
    width: fit-content;
    margin: auto;
}

.sub_Title {
    margin: 10px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sub_Title2 {
    width: 100%;
}

.sub_Title h5, .sub_Title2 h5 {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
}