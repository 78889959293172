@tailwind base;
@tailwind components;
@tailwind utilities;
@import "rsuite/dist/rsuite.css";


html {
  --rs-btn-link-text: #273142 !important;
  --rs-btn-primary-bg: #273142 !important;
  --rs-input-focus-border: #273142 !important;
  --rs-listbox-option-hover-bg: #16203199 !important;
  --rs-listbox-option-hover-text: #fff !important;
  --rs-bg-active: #273142 !important;
  --rs-calendar-cell-selected-hover-bg: #273142 !important;
  --rs-calendar-range-bg: #273142 !important;
  --rs-btn-primary-hover-bg: #273142 !important;
  --rs-btn-primary-active-bg: #273142b1 !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.date-picker {
  border: 1.5px solid rgba(201, 201, 201, 1);
  border-radius: 4px;
  width: 115px;
}


@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&family=Poppins:wght@400;500;600&family=Roboto:wght@300;500;700&display=swap');

.ql-container.ql-snow {
  border: none !important;
}

.MuiTypography-body1 {
  font-size: 14px !important;
}

.reportLayout .ql-indent-1 {
  width: 100% !important;
  padding: 0 !important;
  /* display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr !important; */
}

.reportLayout .ql-indent-1 strong,
.reportLayout .ql-indent-8 strong {
  width: 30% !important;
  display: inline-block;
}

.reportLayout {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.reportLayout .ql-indent-1 strong {
  font-size: 14px !important;
  color: #464E5F !important;
  font-weight: 400 !important;
  padding: 4px 0 !important;
}

.reportLayout .ql-indent-8 {
  border-top: 1px solid #e5e7eb !important;
  border-bottom: 1px solid #e5e7eb !important;
  padding: 16px 0 !important;
  color: #464E5F !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.reportLayout h3 {
  font-size: 14px !important;
}

.reportLayout .ql-align-center {
  font-weight: 500 !important;
  width: fit-content !important;
  color: rgb(70, 78, 95) !important;
  font-size: 16px !important;
  margin: 40px auto !important;
  border-bottom: 1px solid rgb(70, 78, 95) !important;
}

.rs-anim-fade.rs-anim-in {
  z-index: 10000 !important;
}

.rs-btn-primary {
  background: #273142;
}

.rs-calendar-table-cell-in-range {
  color: #fff;
}

.uploading {
  width: 100%;
  height: 100vh;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.docEditorview .e-documenteditorcontainer {
  height: 600px !important;
}

/* .docEditorx > div {
  height: auto !important;
}

.docEditorx > .e-documenteditor-optionspane > div {
  height: auto !important;
} */