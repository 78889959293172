.container {
    width: 100%;
    background: #f5f5f5;
    padding: 2% 0;
}

.wrapper {
    padding: 2% 20px;
    width: 88%;
    margin: auto;
    background: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wrapper h5 {
    font-weight: 500;
    font-size: 18px;
}

.form {
    padding: 40px 0;
    width: 80%;
    margin: auto;
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 1fr 1fr;
}

.heading {
    color: #B82C3A;
    font-size: 18px;
    font-weight: 500;
    margin-top: 15px;
}

.sub_heading {
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

.sub_Title {
    margin: 18px 0;
    width: 100%;
    border-bottom: 5px solid #B82C3A;
    padding-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sub_Title2 {
    width: 100%;
}

.sub_Title h5, .sub_Title2 h5 {
    color: #464E5F;
    font-size: 14px;
    font-weight: 500;
}